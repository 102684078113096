import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import styles from "./report.module.css";
import { errorCodes, errorCodeDescrition } from "../Utils/ContantKeys.js";
import swal from "sweetalert";
import IconWrapper from "../Common/IconWrapper/IconWrapper.jsx";

import {
  columnForDepositTable,
  columnForEKYCTable,
  columnForGuaranteeTable,
  columnForLedgerTable,
  columnForPayin,
  columnForPayoutTable,
  columnForReconciliationTable,
  columnForReleaseReport,
  columnForSattlementTable,
  columnForVendorTable,
  columnForWithdrawTable,
  columnforChargeback,
  columnforHoldAmount,
  columnforWalletBalance,
  columnForsettlementAdjustmentReport,
} from "../Utils/ColumnForReports.js";

import Pagination from "@mui/material/Pagination";

import {
  GET_REPORT_API,
  REPORT_ERROR_CODES,
  GET_REPORT_API_CSV_TIMEFRAME,
} from "../Utils/Constants.js";
import useApiServices from "../Utils/useApiService.js";
import Morefilter from "../Components/Morefilter/Morefilter.jsx";
import {
  setFilterOn,
  setInitialObject,
} from "../store/Slices/reportFilterSlice.js";
import { useDispatch, useSelector } from "react-redux";

import FloatingReports from "../Common/FloatingIcon/FlotingReports.jsx";
import { Box, Typography } from "@mui/material";
import { checkThreeMonthAgoDate, convertTime } from "../Utils/helper.js";
import Shimmer from "../Common/Shimmer/Shimmer.jsx";
import NoDataFound from "../Common/NoDataFound/NoDataFound.jsx";

// const columns = columnForPayin;

/**
 * report component renders Report page.
 * @description it takes the type then call api accordingbto the type and render the data into the table.
 * @returns {ReactNode} A React element that renders report page.
 */
function Reports({ type }) {
  const [data, setData] = useState([]);

  const [showLoader, setShowLoader] = useState(true);
  // const [showMoreFilter, setShowMoreFilter] = useState(true);
  const [count, setCount] = useState(14);
  const [condition, setCondition] = useState("1 day");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [errorCodelist, setErrorCodeList] = useState([]);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [showFilter, setShowFilter] = useState(false);

  const [flag, setFlag] = useState(false);
  const { apiCallFnforPost } = useApiServices();
  const { mid } = useSelector((state) => state.userData);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const advanceFilters = {
    Payin: true,
    Settlement: true,
    Chargeback: true,
    Payout: true,
    Reconciliation: true,
    Release: true,
  };

  const moreFilters = {
    Payin: true,
    Settlement: true,
    Chargeback: true,
    Payout: true,
    Withdraw: true,
    Wallet: true,
    Reconciliation: true,
    Guarantee: true,
    Release: true,
  };

  const handleButtonClick1 = () => {
    swal({
      title: "Alert!",
      text: "Service Not Activated yet.",
      icon: "error",
    });
  };

  const Columns = {
    Payin: columnForPayin,
    Payout: columnForPayoutTable,
    Chargeback: [
      ...columnforChargeback,
      {
        headerName: "Actions",
        field: "active",
        minWidth: 500,
        renderCell: (params) => (
          <div>
            <button
              className="btn btn-sm btn-success me-2"
              onClick={handleButtonClick1}
            >
              Accept / Refund
            </button>

            <button
              className="btn btn-sm btn-danger"
              onClick={handleButtonClick1}
            >
              Denied / fight
            </button>
          </div>
        ),
      },
    ],
    Vendors: columnForVendorTable,
    Withdraw: columnForWithdrawTable,
    Ekyc: columnForEKYCTable,
    Deposit: columnForDepositTable,
    Guarantee: columnForGuaranteeTable,
    Release: columnForReleaseReport,
    Reconciliation: [
      ...columnForReconciliationTable,
      {
        headerName: "Actions",
        field: "active",
        minWidth: 500,
      },
    ],
    Hold: columnforHoldAmount,
    Wallet: columnforWalletBalance,
    Credit: columnforWalletBalance,
    Ledger: columnForLedgerTable,
    Settlement: columnForSattlementTable,
    SettlementAdjustment: columnForsettlementAdjustmentReport,
  };

  const { filterOn } = useSelector((state) => state.reportFilter);

  const dispatch = useDispatch();

  const getDataForDoenloadTimeFrame = async () => {
    try {
      const response = await apiCallFnforPost(GET_REPORT_API_CSV_TIMEFRAME, {
        mid: mid,
        reportType: type,
        timeFrame: condition,
      });
      if (response.statusCode === 200) {
        const DataForDownload = response?.data?.map((item, i) => {
          return {
            ...item,
            id: i,
          };
        });

        return DataForDownload;
      } else {
        setDataForDownload([]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // const fetchDataForVendors = async () => {
  //   try {
  //     // setShowLoader(true);
  //     const response = await apiCallFnforGet(FETCH_ALL_VENDERS_BY_MID + mid);

  //     if (response.statusCode === 200) {
  //       const finalres = response.data.map((item, i) => ({
  //         id: i,
  //         date: item.updateDate || "",
  //         email: item.email || "",
  //         userVPA: item.upi || "",
  //         mid: item.mid || "",
  //         mobile: item.mobile || "",
  //         remark: item.remark || "",
  //       }));
  //       setDataForDownload(finalres);
  //     } else {
  //       setDataForDownload([]);
  //     }
  //     // setShowLoader(false);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // const fetchDataForEkyc = async () => {
  //   try {
  //     // setShowLoader(true);
  //     const response = await apiCallFnforPost(VIEW_E_KYC_REPORT, { mid: mid });
  //     if (response.statusCode === 200) {
  //       const finalres = response.data.map((item, i) => ({
  //         id: i,
  //         requestFor: item.requestFor || "NA",
  //         requestDateAndTime: item.requestDateAndTime || "NA",
  //         requestParameter: "VERIFIED",
  //         merchantId: item.merchantId || "NA",
  //         responseParameter: item.responseParameter || "NA",
  //         amount: item.amount || "NA",
  //       }));
  //       setDataForDownload(finalres);
  //     } else {
  //       setDataForDownload([]);
  //     }
  //     // setShowLoader(false);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // const Columns = {
  //   Payin: columnForPayin,
  //   Payout: columnForPayoutTable,
  //   Chargeback: columnforChargeback,
  //   Vendors: columnForVendorTable,
  //   Withdraw: columnForWithdrawTable,
  //   Ekyc: columnForEKYCTable,
  //   Deposit: columnForDepositTable,
  //   Guarantee: columnForGuaranteeTable,
  //   Release: columnForReleaseReport,
  //   Reconciliation: columnForReconciliationTable,
  //   Hold: columnforHoldAmount,
  //   Wallet: columnforWalletBalance,
  //   Credit: columnforWalletBalance,
  //   Ledger: columnForLedgerTable,
  //   Settlement: columnForSattlementTable,
  //   SettlementAdjustment: columnForsettlementAdjustmentReport,
  // };

  const columnForTable = Columns[type || "Payin"];

  useEffect(() => {
    if (filterOn) {
      return;
    }
    setData([]);
    setTransactionDetails([]);

    fetchPayInData();
  }, [flag, page, condition, size]);

  useEffect(() => {
    if (filterOn) {
      return;
    }

    setErrorCodeList([]);
    fetchErrorCodes();
  }, [flag, condition]);

  const fetchPayInData = async () => {
    console.log(size);
    try {
      setShowLoader(true);
      const response = await apiCallFnforPost(
        `${GET_REPORT_API}?size=${size}&page=${page - 1}`,
        {
          mid: mid,
          reportType: type,
          timeFrame: condition,
        }
      );

      if (response?.statusCode === 200) {
        const finalres = response?.data?.map((item, i) => ({
          id: i + 1,

          sentToBankDate: "",
          ackFromBankDate:
            convertTime(item?.ackFromBankDate?.split(" ")[1]) || "",
          qrSentToMerchantDate:
            convertTime(item?.qrSentToMerchantDate?.split(" ")[1]) || "",
          responseTime: item?.responseTime || "",
          CallbackReceivedFromBank: item?.responseTime || "",
          callbackSentToMerchantDate:
            convertTime(item?.callbackSentToMerchantDate?.split(" ")[1]) || "",
          ackReceivedFromMerchantDate:
            convertTime(item?.ackReceivedFromMerchantDate?.split(" ")[1]) || "",

          callbackJsonExist:
            item?.callbackJson != null ? "Received" : "Not Received",

          SettledAmountMod:
            item?.netAmount - (item?.deposoitAmount + item?.holdAmount),
          evokTxnCodeMode:
            item.txnStatus === "success" ? "0" : item.evokTxnCode,
          // item?.evokTxnCode === null || item?.evokTxnCode === "null"  ? "0" :item?.evokTxnCode,
          releaseFrom: item?.releaseFrom?.toUpperCase() || "NA",
          transferMode: item?.transferMode?.toUpperCase() || "NA",
          status: item?.status?.toUpperCase() || "",
          transactionType: item?.transactionType?.toUpperCase() || "NA",
          requestedAmount: item?.actualAmount,
          domain: item?.domain,
          remark:
            item?.remark === "SurCharge"
              ? `SurCharge of ${item.reference?.split("-")[0]}`
              : item?.remark,
          ...item,
        }));

        setData(finalres);
        setTransactionDetails(response?.data1);
        setShowLoader(false);
      } else {
        setData([]);
        setShowLoader(false);
      }
      setShowLoader(false);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const fetchErrorCodes = async () => {
    console.log(type);
    if (!(type === "Payin")) {
      return;
    }
    try {
      const response = await apiCallFnforPost(REPORT_ERROR_CODES, {
        mid: mid,
        timeFrame: condition,
      });

      if (response?.statusCode === 200) {
        setErrorCodeList(response?.data);
      }
    } catch (error) {
      console.log("error::", error);
    }
  };

  useEffect(() => {
    dispatch(
      setInitialObject({
        dateoption: "",
        otherDateoption: "",
        date: "",
        startDate: "",
        endDate: "",
        otherdate: "",
        otherStartDate: "",
        otherEndDate: "",
        parameterType: "",
        parameterValue: "",
        transectionStatus: "",
        transferType: "",
      })
    );
  }, [type]);

  const incrementZoom = () => {
    {
      count < 28 && setCount(count + 1);
    }
  };

  const decrementZoom = () => {
    {
      count > 14 && setCount(count - 1);
    }
  };

  const handleReset = async () => {
    setPage(1);
    setSize(10);
    setCondition("1 day");
    dispatch(setInitialObject());
    dispatch(setFilterOn(false));
    setFlag(!flag);
  };

  const navigate = useNavigate();

  const reduxData = useSelector((state) => state.reportFilter);
  const { date, startDate } = reduxData;

  function CustomToolbar() {
    const openModal = () => {
      swal(
        "Maximum Transactions Limit Exceeds, Please Download From Archieve Reports.",
        {
          buttons: {
            cancel: "Cancel",
            confirm: {
              text: "Go to Archived Report",
              value: "ok",
            },
          },
        }
      ).then((value) => {
        if (value === "ok") {
          swal.close();
          // 1 is stand for archieve report
          navigate("/Tools/6");
        }
      });
    };

    const downloadCsv = (csvContent, filename) => {
      if (!csvContent) {
        return;
      }

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const generateCsvContent = (data) => {
      if (!data) {
        return;
      }
      // Assuming your data is an array of objects, each representing a row in the CSV
      const headers = Object?.keys(data[0]);
      const csvContent = [
        headers.join(","), // Write headers as the first line
        ...data.map((row) => headers?.map((header) => row[header])?.join(",")), // Write data rows
      ]?.join("\n");

      return csvContent;
    };

    const onDownloadCsvData = (data, filename) => {
      if (!data) {
        return;
      }

      const csvContent = generateCsvContent(data);
      downloadCsv(csvContent, filename);
    };

    const handleDownloadCsv = async () => {
      const dataForDownload = await getDataForDoenloadTimeFrame();

      if (!dataForDownload?.length) {
        return;
      }

      onDownloadCsvData(dataForDownload, type);
    };

    return (
      <GridToolbarContainer className="d-flex gap-3 ">
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />

        {(transactionDetails?.txnCount || transactionDetails?.txnCountt) >
          2000 || checkThreeMonthAgoDate(date ? date : startDate) ? (
          <div
            onClick={openModal}
            className={`d-flex align-items-center gap-2 ${styles["ColorCustom"]}`}
          >
            {" "}
            <span
              className={`mb-1 ${styles["ColorCustomSecond"]} ${styles["fontWFirst"]}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-download fw-bold"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
              </svg>
            </span>
            <span
              className={`${styles["ColorCustomSecond"]} ${styles["fontWFirstSecond"]}`}
            >
              EXPORT
            </span>
          </div>
        ) : (
          <>
            {filterOn ? (
              <GridToolbarExport />
            ) : (
              <div
                onClick={handleDownloadCsv}
                className={`d-flex align-items-center gap-2 ${styles["ColorCustom"]}`}
              >
                {" "}
                <span
                  className={`mb-1 ${styles["ColorCustomSecond"]} ${styles["fontWFirst"]}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-download fw-bold"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                  </svg>
                </span>
                <span
                  className={`${styles["ColorCustomSecond"]} ${styles["fontWFirstSecond"]}`}
                >
                  EXPORT
                </span>
              </div>
            )}
          </>
        )}
        <GridToolbarQuickFilter />
        <Box sx={{ flexGrow: 1 }} />
        <div class="row">
          <div class="col text-end">
            <span class="m-b-0 me-2 fs-18 text-success">
              {type === "Settlement"
                ? "Total Settled Amount"
                : "Success amount"}{" "}
              :
              <span className="mx-2">
                {transactionDetails?.sumOfSuccessAmount}
              </span>
            </span>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleFiler = (type) => {
    setShowFilter(!showFilter);
  };

  const handleClose = (type) => {
    setShowFilter(false);
  };

  return (
    <>
      <div className={"container-fluid"}>
        <div className="row">
          <div className="col-12">
            <div className="card ">
              <div className="card-header  bg-white d-flex justify-content-between report-card">
                <h3 className="card-title">{type} Report</h3>

                <div className="col-7  mb-sm-0 text-right">
                  <IconWrapper title={"Zoomin"}>
                    <span class="" onClick={incrementZoom}>
                      <i class="fa-solid fa-magnifying-glass-plus text-dark"></i>{" "}
                    </span>
                  </IconWrapper>
                  <IconWrapper title={"Zoomout"}>
                    <span class="" onClick={decrementZoom}>
                      <i class="fa-solid fa-magnifying-glass-minus text-dark"></i>{" "}
                    </span>
                  </IconWrapper>
                  {type === "Ledger" || type === "Release" || type === "Wallet" ? null : (
                    <IconWrapper title={"filter"}>
                      <span class="" onClick={() => handleFiler(type)}>
                        <i class="fa fa-filter text-dark"></i>
                      </span>
                    </IconWrapper>
                  )}

                  <Morefilter
                    page={page - 1}
                    size={size}
                    type={type}
                    setData={setData}
                    setTransactionDetails={setTransactionDetails}
                    setErrorCodeList={setErrorCodeList}
                    showFilter={showFilter}
                    handleClose={handleClose}
                  />

                  {moreFilters[type] || advanceFilters[type] ? (
                    <IconWrapper title={"reset"}>
                      <i
                        className="fa fa-refresh text-dark"
                        onClick={handleReset}
                      ></i>
                    </IconWrapper>
                  ) : null}
                  {type === "Wallet" ? (
                    <Link className="text-black" to="/Tools/5">
                      <IconWrapper title={"Go to Add Wallet Balance"}>
                        <i class="fa-solid fa-location-arrow text-dark"></i>
                      </IconWrapper>
                    </Link>
                  ) : null}
                  {type === "Withdraw" ? (
                    <Link className="text-black" to="/Tools/1">
                      <IconWrapper title={"Go to Withdraw Amount"}>
                        <i class="fa-solid fa-location-arrow text-dark"></i>
                      </IconWrapper>
                    </Link>
                  ) : null}
                  {type === "Payout" ? (
                    <Link className="text-black" to="/Tools/2">
                      <IconWrapper title={"Go to Payout Request"}>
                        <i class="fa-solid fa-location-arrow text-dark"></i>
                      </IconWrapper>
                    </Link>
                  ) : null}
                </div>
              </div>
              <div className="">
                {type === "Payin" && (
                  <div>
                    <div>
                      <div className="d-flex gap-2  ">
                        <div
                          class={`d-flex justify-content-start overflow-auto ${styles["report_scrollbarnone__rfOhF"]}`}
                        >
                          <ul class="list-unstyled d-flex py-2 gap-2 flex-nowrap">
                            {errorCodelist?.length !== 0 &&
                              errorCodelist?.map((errcode, i) => {
                                return (
                                  <li
                                    key={i}
                                    style={{
                                      color: "black",
                                      backgroundColor:
                                        errorCodes[errcode?.evokTxnCode],
                                    }}
                                    class="badge badge-rounded badge-primary"
                                  >
                                    {`${errcode?.evokTxnCode} : ${
                                      errcode?.percentage ?? 0
                                    }%`}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="d-flex align-items-center py-2">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#bd-example-modal-lg"
                            class="badge badge-rounded badge-dark text-white"
                          >
                            More
                          </a>
                        </div>
                      </div>

                      <div
                        class="modal fade"
                        id="bd-example-modal-lg"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-lg">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Error codes</h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>
                            <div class="modal-body p-0">
                              {type == "Payin" && (
                                <div class="table-responsive">
                                  <table class="table table-responsive-md p-0">
                                    <thead className="p-auto">
                                      <tr className="p-auto">
                                        <th>
                                          <strong>Error code</strong>
                                        </th>
                                        <th>
                                          <strong>Count</strong>
                                        </th>
                                        <th>
                                          <strong>Percentage</strong>
                                        </th>
                                        <th>
                                          <strong>Description</strong>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {errorCodelist?.length !== 0 &&
                                        errorCodelist
                                          ?.reverse()
                                          .map((errcode) => {
                                            return (
                                              <tr className="p-auto">
                                                <td className="p-auto">
                                                  <span
                                                    class="badge light badge-success"
                                                    style={{
                                                      backgroundColor:
                                                        errorCodes[
                                                          errcode?.evokTxnCode
                                                        ],
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {errcode?.evokTxnCode}
                                                  </span>
                                                </td>
                                                <td> {errcode?.count}</td>
                                                <td>{errcode?.percentage}%</td>
                                                <td>
                                                  {
                                                    errorCodeDescrition[
                                                      errcode?.evokTxnCode
                                                    ]
                                                  }
                                                </td>
                                              </tr>
                                            );
                                          })}
                                    </tbody>
                                  </table>
                                </div>
                              )}

                              <div className="card">
                                <div className="card-body">
                                  <div class="col-md-12">
                                    <div class="card-body d-flex card-flex-col ">
                                      <div class="me-auto responsive-card-title ">
                                        <h4 class="card-title">
                                          Total Transactions
                                        </h4>
                                        <div class="d-flex align-items-center">
                                          <h2 class="fs-38 mb-0">
                                            {transactionDetails?.txnCount}
                                          </h2>
                                        </div>
                                      </div>
                                      <div class="text-center">
                                        <div class="row">
                                          <div class="col">
                                            <h3 class="m-b-0 text-success">
                                              {transactionDetails?.successCount}
                                            </h3>
                                            <span className="text-success">
                                              Success
                                            </span>
                                          </div>
                                          <div class="col">
                                            <h3 class="m-b-0 text-danger">
                                              {transactionDetails?.failureCount}
                                            </h3>
                                            <span className="text-danger">
                                              Failed
                                            </span>
                                          </div>
                                          <div class="col">
                                            <h3 class="m-b-0 text-yellow">
                                              {transactionDetails?.pendingCount}
                                            </h3>
                                            <span className="text-yellow">
                                              Pending
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-danger light"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {showLoader ? (
                  <Shimmer type="table" />
                ) : data?.length > 0 ? (
                  <div
                    className={`card-body no-scrollbar ${styles["DataGridHeightClass"]}`}
                  >
                    <DataGrid
                      className="custom-scrollbar"
                      rows={data}
                      columns={
                        type === "Payin" ? [...columnForPayin] : columnForTable
                      }
                      slots={{
                        toolbar: CustomToolbar,
                      }}
                      pageSizeOptions={[10]}
                      hideFooterPagination={true}
                      sx={{
                        fontSize: `${count}px`,
                        "& .css-1rtad1": {
                          display: "block",
                        },
                      }}
                      // scrollbarSize={0}
                    />

                    <div className="d-flex justify-content-center mt-1">
                      <select
                        id="pageSize"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Pagination
                          count={Math.ceil(
                            (transactionDetails?.txnCount ||
                              transactionDetails?.txnCountt) / size
                          )} // Assuming itemsPerPage is defined
                          page={page}
                          onChange={handleChange}
                        />
                        <Typography className="text-black" variant="body1">
                          Total Transactions: {transactionDetails?.txnCount}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingReports
        setCondition={setCondition}
        condition={condition}
        setPage={setPage}
        setSize={setSize}
      />
    </>
  );
}

export default Reports;
