import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import IbtnWrapper from "../../Common/CustomToolTip/IButtonWrraper";
import CustomTip from "../../Common/CustomToolTip/CustomTip";
import { errorCodeDescrition } from "../../Utils/ContantKeys";
import Shimmer from "../../Common/Shimmer/Shimmer";

/**
 * PieCharts component renders a bar chart.
 * prepare the pie chart according to the received data.
 * @param {string} condition -  condition.
 * @param {object[]} data -  array of the objects.
 * @param {string} type -  type .
 * @returns {ReactNode} A React element that renders the pie chart.
 */
const PieCharts = ({ data, type ,condition  }) => {
  return (
    <div className={"col-xl-12 col-xxl-12 col-lg-12"}>
      <div className="card">
        <div className="card-header border-0">
          <h4 className="card-title">
            {type === "services"
              ? `Services Percentage Chart (${condition === "1 day" ? "Today" : condition})`
              : `Error Code & Count Chart (${condition === "1 day" ? "Today" : condition})`}
          </h4>
          <IbtnWrapper
            color={"text-black"}
            desc={
              type === "services"
                ? "Services Percentage Chart"
                : "Error Code & Count Chart"
            }
          />
        </div>
        {/* <div className="card-body pt-0 text-center "> */}
        {/* <div id="pieChart2" className="d-inline-block pieChart1"></div> */}
        <div className="card-body pt-0 text-center ">
          {
            data?.length == 0 ? (
              <div className="py-3">
                  <Shimmer type={"circle"} size={300}  />
                </div>
            ) : (
              <div style={{ width: "100%", height: "18rem", display: "flex" }} className="res-service-chart">
            {data && (
              <PieChart
                series={[
                  {
                    data: data,
                    innerRadius: 30,
                    // outerRadius: 100,
                    paddingAngle: 1,
                    cornerRadius: 5,
                    // startAngle: -90,

                    // endAngle: 300,
                    // cx: 150,
                    // cy: 150,
                  },
                ]}
                slotProps={{
                  legend: { hidden: true },
                }}
              />
            )}
            <div style={{ width: "100%", height: "100%" }} className="row">
              {data &&
                data.map((item, i) => (
                  <div  className="col-4 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-start align-item-center">
                    <CustomTip
                      size={20}
                      title={type === "services" ? `${item.label} : ${item.value} ` : `${item.label} : ${item.value} \n ${errorCodeDescrition[item.label] ?? ""}`}
                      placement="top"
                      
                      // dangerouslySetInnerHTML={{ __html: type === "services" ? `${item.label}: ${item.value} ` : ${item.label}: ${item.value}<br/> + errorCodeDescrition[item.label] }}
                    >
                      <div
                        key={item.id}
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: `${item.color}`,
                        }}
                        className="mx-2 cursor-pointer"
                      ></div>
                    </CustomTip>
                    <p className="text-dark">
                      {item.label === "VIRTUAL TERMINAL" ? "VT" : item.label}
                    </p>
                  </div>
                ))}
            </div>
          </div>
            )
          }
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default PieCharts;