import swal from "sweetalert";

import SwipelincLogo from "../Assets/images/SwipelincLogo.png";
import LincpayLogo from "../Assets/images/LincpayLogo.png";
import SilverPeLogo from "../Assets/images/SilverPeLogo.jpeg";
import icepeLogo from "../Assets/images/icepeLogo.png";

export const getServerEnvironment = (hostName) => {
  if (hostName === "login.lincpay.in") return "productionLincpay";
  else if (hostName === "login.swipelinc.com") return "productionSwipelinc";
  else if (hostName === "login.icepe.in") return "productionIcepe";
  else if (hostName === "login.silverpe.in") return "productionSilverPe";
  else if (hostName === "godemo.in") return "GoDemodevelopment";
  else if (hostName === "localhost" || hostName === "uatmerchant1.lincpay.in")
    return "development";
  // return "productionIcepe";
  else {
    swal({
      title: "ALERT!",
      text: `Invalid Host "${hostName}" !! Please Contact to Admin`,
      icon: "error",
    }).then(() => {
      return;
    });
    return;
  }
};

export function getCompleteDomain() {
  const { protocol, hostname, port } = window.location;

  return hostname;
}

export const config = (serverEnvironment) => {
  const logos = {
    productionLincpay: LincpayLogo,
    productionSilverPe: SilverPeLogo,
    productionSwipelinc: SwipelincLogo,
    development: LincpayLogo,
    GoDemodevelopment: LincpayLogo,
    productionIcepe: icepeLogo,
  };
  const bankDetails = {
    productionLincpay: {
      BName: "LincPay Solutions Private Limited",
      bankName: "IDFC Bank",
      branch: "Bhopal Kolar Road",
      Account: "10152683089",
      ifsc: "IDFB0041386",
    },
    productionSwipelinc: {
      BName: "Zenex e-Commerce Private Limited",
      bankName: "AU Small Finance Bank",
      branch: "Malad Branch",
      Account: "2021234226901022",
      ifsc: "AUBL0002342",
    },
    development: {
      BName: "LincPay Solutions Private Limited",
      bankName: "IDFC Bank",
      branch: "Bhopal Kolar Road",
      Account: "10152683089",
      ifsc: "IDFB0041386",
    },
    GoDemodevelopment: {
      BName: "LincPay Solutions Private Limited",
      bankName: "IDFC Bank",
      branch: "Bhopal Kolar Road",
      Account: "10152683089",
      ifsc: "IDFB0041386",
    },

    productionSilverPe: {
      BName: " XYZ Pvt. Ltd.",
      bankName: "XYZ Bank",
      branch: "XYZ Branch",
      Account: "000XXXXXXXX000",
      ifsc: "ABCD00000",
    },
    productionIcepe: {
      BName: "ICEWEB TECHNOLOGY SOLUTIONS (OPC) PVT LTD",
      bankName: "IDFC FIRST BANK",
      branch: "SEAWOODS BRANCH",
      Account: "10151493000",
      ifsc: "IDFB0040158",
    },
  };
  const Names = {
    productionLincpay: "Lincpay",
    productionSilverPe: "SilverPe",
    productionSwipelinc: "Swipelinc",
    development: "Lincpay",
    GoDemodevelopment: "Lincpay",
    productionIcepe: "Icepe",
  };

  const apiDocsUrl = {
    productionIcepe: {
      paymentIntegrationKitUrl: "https://pg.icepe.in/paymentrequest/seamless",
    },
    productionSilverPe: {
      paymentIntegrationKitUrl:
        "https://payment.silverpe.in/paymentrequest/seamless",
    },
    development: {
      paymentIntegrationKitUrl: "https://pg.icepe.in/paymentrequest/seamless",
    },
    GoDemodevelopment: {
      paymentIntegrationKitUrl: "https://pg.icepe.in/paymentrequest/seamless",
    },
    productionLincpay: {
      paymentIntegrationKitUrl: "https://pg.icepe.in/paymentrequest/seamless",
    },
    productionSwipelinc: {
      paymentIntegrationKitUrl: "https://pg.icepe.in/paymentrequest/seamless",
    },
  };
  const Urls = {
    productionLincpay: "https://api.lincpay.in/",
    productionSwipelinc: "https://api.icepe.in/",
    productionSilverPe: "https://api.icepe.in/",
    // development: 'https://uatmerchant.lincpay.in/',
    development: "https://api2.lincpay.in/",
    GoDemodevelopment: "https://godemo.in/api/",
    productionIcepe: "https://login.icepe.in/api/",
  };
  const logoPath = logos[serverEnvironment];
  const Name = Names[serverEnvironment];
  const Url = Urls[serverEnvironment];
  const bankDeatails = bankDetails[serverEnvironment];
  const panelApiDocsUrl = apiDocsUrl[serverEnvironment];

  return {
    logoPath,
    Name,
    Url,
    bankDeatails,
    panelApiDocsUrl,
  };
};
