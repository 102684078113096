import React, { useState } from "react";
import swal from "sweetalert";
import useApiServices from "../../Utils/useApiService";
import {
  SAVE_MISSING_CALLBACK,
  UPLOAD_CALLBACK_CSV,
  UPLOAD_PAYOUT_TXN_CALLBACK_CSV,
} from "../../Utils/Constants";
import { jwtDecode } from "jwt-decode";
import PayoutCallback from "../../Assets/templateFiles/payoutCallback.csv";
import CallbackFile from "../../Assets/templateFiles/callback.csv";
import CheckExportConst from "../../Utils/PrivilagesUtilityFun/CheckExportConst";
import { useSelector } from "react-redux";


/** 
 * @returns {ReactNode} - This component renders Payin and Payout transaction callback elements.
*/

const TransactionCallback = () => {
  const { apiCallFnforPost } = useApiServices();
  const [flag, setFlag] = useState(true);
  const [orderNo, setOrderNo] = useState("");
  const [file, setFile] = useState();
  const [payoutfile, setPayoutFile] = useState();

  const handleRadioChange = (event) => {
    setFlag(event.target.value === "true"); // Convert string value to boolean
  };
  const { mid } = useSelector((state) => state.userData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (flag) {
        if (!orderNo) {
          swal({
            title: "Alert!",
            text: "Please Enter Order Number",
            icon: "error",
          });
          return;
        }

        const response = await apiCallFnforPost(SAVE_MISSING_CALLBACK, {
          mid: mid,
          orderId: orderNo,
        });


        if (response.statusCode !== 200) {
          setOrderNo("")
          swal({
            title: "Error!",
            text: response?.message,
            icon: "error",
          });
          return;
        }


        swal({
          title: "Success!",
          text: response?.message,
          icon: "success",
        });
        return;
      } else {
        if (!file) {
          swal({
            title: "Alert!",
            text: "Please upload file",
            icon: "error",
          });
          return;
        }

        const formData = new FormData();
        formData.append("mid", mid);
        formData.append("file", file);

        const response = await apiCallFnforPost(UPLOAD_CALLBACK_CSV, formData);

        if (response.statusCode !== 200) {
          swal({
            title: "Error",
            text: response?.message,
            icon: "error",
          });
          return;
        }

        swal({
          title: "Success!",
          text: response?.message,
          icon: "success",
        });
        return;
      }
    } catch (error) {
      swal({
        title: "Alert!",
        text: "Something went wrong",
        icon: "error",
      });
      return;
    }
  };

  const handlePayoutSubmit = async (e) => {
    e.preventDefault();
   
    try {
      if (!file) {
        swal({
          title: "Alert!",
          text: "Please upload file",
          icon: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("mid", mid);
      formData.append("file", file);

      const response = await apiCallFnforPost(
        UPLOAD_PAYOUT_TXN_CALLBACK_CSV,
        formData
      );

      if (response.statusCode !== 200) {
        swal({
          title: "Error",
          text: response?.message,
          icon: "error",
        });
        return;
      }

      swal({
        title: "Success!",
        text: response?.message,
        icon: "success",
      });
      return;
    } catch (error) {
      swal({
        title: "Alert!",
        text: "Something went wrong",
        icon: "error",
      });
      return;
    }
  };

  const { hasPrivilagesTxnCallback, hasPrivilagesPayoutTxnCallback } =
    CheckExportConst();

  return (
    // <div className="content-body">
    <div className="container-fluid">
      <div className="col-xl-12 col-lg-12 d-flex gap-2">
        <div className="row col-lg-12 row-cols-1 row-cols-lg-2 row-cols-xl-2">
          {hasPrivilagesTxnCallback ? (
            <div className="col">
              <div class="card border col-lg-12">
                <div class="card-header">
                  <h4 class="card-title">Payin Transaction Callback</h4>
                </div>
                <div class="card-body d-flex flex-column flex-start">
                  <div class="basic-form">
                    <form class="d-flex justify-content-center">
                      <div class="d-flex gap-5 sm-gap-0">
                        <label class="radio-inline me-3 fs-5">
                          <input
                            type="radio"
                            class="form-check-input me-2"
                            name="optradio"
                            value={true}
                            checked={flag}
                            onChange={handleRadioChange}
                          />{" "}
                          Order Number
                        </label>
                        <label class="radio-inline me-3 fs-5">
                          <input
                            type="radio"
                            class="form-check-input me-2"
                            name="optradio"
                            value={false}
                            checked={!flag}
                            onChange={handleRadioChange}
                          />{" "}
                          CSV File
                        </label>
                      </div>
                    </form>
                  </div>

                  {flag ? (
                    <div class="basic-form mt-3">
                      <form class="d-flex justify-content-between gap-3 align-items-center">
                        {/* <div class="mb-3 me-3 col-md-6"> */}
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Order Number"
                            value={orderNo}
                            onChange={(e) => setOrderNo(e.target.value)}
                          />
                        {/* </div> */}
                        <div class="mt-1 me-2 col-md-3">
                          <button
                            type="submit"
                            class="form-control btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div class="basic-form mt-3">
                      <form class="d-flex justify-content-between gap-3 align-items-center">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="upload csv file"
                          onChange={(e) => setFile(e.target.files[0])}
                        />

                        <div class="mt-1 me-2 col-md-3">
                          <button
                            type="submit"
                            class="form-control btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                      <div className="d-flex justify-content-between gap-3 align-items-center">
                        <span className="text-primary mt-1 me-2 col-md-8">
                          {/* <span className="mt-1 me-2 fs-12 fs-md-12  text-primary"> */}
                          Upload missing callback CSV file with Order ID:
                          {/* </span> */}
                        </span>
                        <u className="text-primary mt-1 me-2 col-md-3">
                          <a
                             
                            className="text-primary fs-10"
                            title="Click here to download Callback file template."
                            href={CallbackFile}
                            download
                          >
                            Download
                          </a>
                        </u>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {hasPrivilagesPayoutTxnCallback ? (
            <div className="col">
              <div class="card border col-lg-12">
                <div class="card-header">
                  <h4 class="card-title">Payout Transaction Callback</h4>
                </div>
                <div class="card-body mt-4">
                  <div class="basic-form mt-3">
                    <form class="d-flex justify-content-between gap-3 align-items-center">
                      {/* <div class="mb-3 me-3 col-md-6 d-flex flex-column justify-content-center"> */}
                      <input
                        type="file"
                        class="form-control"
                        placeholder="upload csv file"
                        onChange={(e) => setPayoutFile(e.target.files[0])}
                      />
                      {/* </div> */}
                      <div class="mt-1 me-2 col-md-3">
                        <button
                          type="submit"
                          class="form-control btn btn-primary"
                          onClick={handlePayoutSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                    <div className="d-flex justify-content-between gap-3 align-items-center">
                      <span className="text-primary mt-1 me-2 col-md-8">
                        {/* <span className="mt-1 me-2 fs-12 fs-md-12  text-primary"> */}
                        Upload missing payout Txn callback CSV file:
                        {/* </span> */}
                      </span>
                      <u className="text-primary mt-1 me-2 col-md-3">
                        <a
                           
                          className="text-primary fs-10"
                          title="Click here to download Callback file template."
                          href={PayoutCallback}
                          download
                        >
                          Download
                        </a>
                      </u>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default TransactionCallback;
